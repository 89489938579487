




























































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetUsers,
  dispatchCreateCobroEfectivo,
} from '@/store/admin/actions';

import { ICreateEfectivo } from '@/interfaces';

import { apiUploadFolder } from '@/env';
import { readAdminUsers } from '@/store/admin/getters';

@Component
export default class CreateCobroEfetivo extends Vue {
  public showUserError = false;

  public valid = true;
  public nombre: string = '';
  public descripcion: string = '';
  public importe = 0;
  public nif = '';
  public direccion = '';

  public urlDoc: string = '';
  public urlTipo: string = '';

  public userId: number = 0;
  public async mounted() {
    await dispatchGetUsers(this.$store);

    // this.reset();
  }
  get users() {
    return readAdminUsers(this.$store);
  }
  public url(u) {
    return apiUploadFolder + u;
  }
  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.userId === 0) {
        this.showUserError = true;
      } else {
        const updatedProfile: ICreateEfectivo = {
          user_id: this.userId
        };
        this.showUserError = false;
        if (this.nombre) {
          updatedProfile.nombre = this.nombre;
        }
        if (this.descripcion) {
          updatedProfile.descripcion = this.descripcion;
        }
        if (this.importe) {
          updatedProfile.importe = this.importe;
        }
        if (this.nif) {
          updatedProfile.nif = this.nif;
        }
        if (this.direccion) {
          updatedProfile.direccion = this.direccion;
        }
        await dispatchCreateCobroEfectivo(this.$store, updatedProfile);
        this.$router.push('/main/admin/cobrosefectivo/all');
      }
    }
  }


}
